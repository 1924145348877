import { gql } from "@apollo/client";

export const GET_PROMOTIONS = gql`
  query getPromotions($locale: I18NLocaleCode, $pagination: PaginationArg) {
    promotions(locale: $locale, pagination: $pagination, sort: "priority:asc") {
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
      data {
        id
        attributes {
          title
          subtitle
          description
          priority
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
