import React, { FC, useState } from "react";
import parse from "html-react-parser";
import { injectIntl } from "gatsby-plugin-intl";
import "./promotions.css";
import { cmsImageLoader } from "../../utils/cmsImageLoader";
import PromoImage from "../../images/yellow_promotion.svg";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

type PromotionItemProps = {
  intl: any;
  title: string;
  subtitle: string;
  description: string;
  image: any;
  priority: number;
};

// @ts-ignore
const PromotionItem: FC<PromotionItemProps> = ({
  intl,
  title,
  subtitle,
  description,
  image,
}) => {
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  // @ts-ignore
  return (
    <div className="mt-[2rem] w-auto h-auto grid sm:grid-flow-col sm:justify-start   lg:gap-[2rem] sm:gap-[1rem] bg-white border border-opacity-25 border-[#618EE4]">
      <div className="promotions-bg flex items-center justify-center object-cover justify-start lg:w-[15.5rem] lg:h-[14rem] sm:w-[16rem] sm:h-[15.9rem] xs:w-[18.6rem] xs:h-[16rem] ">
        {image.data.length ? (
          image.data.map((img: any, idx: number) => (
            <img
              key={idx}
              src={cmsImageLoader({
                src: img.attributes.url,
                width: 0,
              })}
              alt={`Image ${idx + 1}`}
            />
          ))
        ) : (
          <img src={PromoImage} alt="Placeholder Image" />
        )}
      </div>
      <div className="grid py-[1.5rem] pr-[2rem] xs:pl-[0.5rem]">
        <h2 className="text-[1.75rem] font-semibold">{title}</h2>
        <p className="text-[1.25rem] font-medium">{subtitle}</p>
        {/*<button className="mt-[1rem] text-[1.25rem font-semibold border border-black border-1 rounded-[0.56rem] w-[12rem] h-[3rem] flex justify-center items-center">*/}
        {/*  {intl.formatMessage({ id: "ServiceMenu.Promotions.ChooseAnalysis" })}*/}
        {/*</button>*/}

        {showMoreInfo ? (
          <>
            <ReactMarkdown
              className="mt-[1rem] text-[0.875rem] font-regular promotion"
              linkTarget="_blank"
              skipHtml={true}
              rehypePlugins={[rehypeRaw as any]}
            >
              {description}
            </ReactMarkdown>
            <p
              className="mt-[1rem] cursor-pointer text-[1rem] font-medium text-[#618EE4]"
              onClick={() => setShowMoreInfo(false)}
            >
              {intl.formatMessage({ id: "ServiceMenu.Promotions.Collapse" })}
            </p>
          </>
        ) : (
          <p
            className="mt-[1rem] cursor-pointer text-[1rem] font-medium text-[#618EE4]"
            onClick={() => setShowMoreInfo(true)}
          >
            {intl.formatMessage({ id: "ServiceMenu.Promotions.More" })}
          </p>
        )}
      </div>
    </div>
  );
};

export default injectIntl(PromotionItem);
