import * as React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import PromotionItem from "./PromotionItem";
import Pagination from "../Pagination";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_PROMOTIONS } from "../../query/getPromotions";
import Boy from "../../images/boy.png";

// @ts-ignore
const Promotions = ({ intl }) => {
  const [page, setPage] = useState(0);
  const { data, loading, error } = useQuery(GET_PROMOTIONS, {
    variables: {
      locale: intl.locale,
      pagination: {
        pageSize: 10,
        page,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading) return <div>Loading...</div>;

  if (error) return `Error! ${error.message}`;

  const promotionsData = data?.promotions?.data || [];

  // @ts-ignore
  return (
    <div className="flex items-center justify-center bg-[#FAFAFA] ">
      <div className="mb-[10rem] flex flex-col  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
        <div className="ml-[0.2rem] mt-[2.5rem] mb-[2rem] "></div>
        <h2 className="text-[2rem] font-semibold text-[#001439]">
          {intl.formatMessage({ id: "ServiceMenu.Promotions.Title" })}
        </h2>
        {promotionsData.length === 0 ? (
          <div className="mt-3 flex flex-col text-left mb-16 font-[gabdugi] items-center">
            <h2 className="text-[#DA6A00] font-bold  text-[25px] text-center">
              {intl.formatMessage({
                id: "ServiceMenu.Promotions.NoPromotions",
              })}
            </h2>
            <img
              src={Boy}
              alt="Boy"
              className="w-[400px] h-[400px]"
              loading="lazy"
            />
          </div>
        ) : (
          promotionsData.map((item: any) => {
            const prom = item.attributes;
            return (
              <PromotionItem
                key={prom.id}
                intl={intl}
                title={prom.title}
                subtitle={prom.subtitle}
                description={prom.description}
                image={prom.image}
              />
            );
          })
        )}
        <Pagination
          intl={intl}
          setPage={setPage}
          pagination={data?.promotions?.meta?.pagination || {}}
        />
      </div>
    </div>
  );
};

export default injectIntl(Promotions);
