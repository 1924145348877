import * as React from "react";
import Promotions from "../components/promotions/Promotions";
import Layout from "../components/Layout/Layout";

const promotions = () => {
  return (
    <Layout>
      <Promotions />
    </Layout>
  );
};

export default promotions;
